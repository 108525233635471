export const groups = {
    "builders": {
        // @ts-ignore
        background: new URL("../assets/builders.png?as=webp", import.meta.url),
        color: "#ee8d00",
    },
    "email-and-engagement": {
        // @ts-ignore
        background: new URL("../assets/email-and-engagement.png?as=webp", import.meta.url),
        color: "#0060e3",
    },
    "design-and-video": {
        // @ts-ignore
        background: new URL("../assets/video-and-design.png?as=webp", import.meta.url),
        color: "#7d31e5",
    },
    "business-center": {
        // @ts-ignore
        background: new URL("../assets/business-center.png?as=webp", import.meta.url),
        color: "#01d4b9",
    },
    "franchise": {
        // @ts-ignore
        background: new URL("../assets/associate.png?as=webp", import.meta.url),
        color: "#0060e3",
    },
    "elearning": {
        // @ts-ignore
        background: new URL("../assets/elearning.png?as=webp", import.meta.url),
        color: "#EE8D00",
    },
    "email-marketing-5": {
        // @ts-ignore
        background: new URL("../assets/email-marketing-5.png?as=webp", import.meta.url),
        color: "#0060E3",
    },
    "booking": {
        // @ts-ignore
        background: new URL("../assets/booking.png?as=webp", import.meta.url),
        color: "#EE8D00",
    },
    "website-builder": {
        // @ts-ignore
        background: new URL("../assets/website-builder.png?as=webp", import.meta.url),
        color: "#EE8D00",
    },
    "team-access": {
        // @ts-ignore
        background: new URL("../assets/team-access.png?as=webp", import.meta.url),
        color: "#0060e3",
    },
    "social-proof-pop-up": {
        // @ts-ignore
        background: new URL("../assets/social-proof-pop-up.png?as=webp", import.meta.url),
        color: "#0060e3",
    }
};
