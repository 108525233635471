import {customElement} from "solid-element";
import {tools} from "../appshell/metadata";
import {createEffect, createMemo, createSignal} from "solid-js";
import {groups} from "./services/groups";


customElement(
    "builderall-banner",
    {tool: "", title: "", subtitle: "", description: ""},
    (props) => {
        const defaultCollapsed = localStorage.getItem("builderall-banner--collapsed") === "true";
        const [collapsed, setCollapsed] = createSignal(defaultCollapsed);

        const tool = createMemo(() => {
            const {icon, group} = tools[props.tool] || {};
            const {background, color} = groups[group] || {};

            console.log(props.tool, group, background, color)
            return {icon, background, color};
        });

        createEffect(() => {
            localStorage.setItem("builderall-banner--collapsed", collapsed().toString());
        });

        return (
            <>
                <style>{require("bundle-text:./styles/index.scss")}</style>
                <header class="banner" style={{background: `url(${tool().background})`}}
                        classList={{"banner--collapsed": collapsed()}}>
                    <div class="banner__section banner__section--content">
                        <div class="banner__header">
                            <i class="banner__header__icon" style={{background: tool().color}}
                               innerHTML={tool().icon}></i>
                            <h1 class="banner__header__title">{props.title}</h1>
                        </div>
                        <h2 class="banner__subtitle">{props.subtitle}</h2>
                        <p class="banner__description">{props.description}</p>
                    </div>
                    <div class="banner__section">
                        <div class="banner__toggle"
                             innerHTML={require("bundle-text:./assets/arrow-toggle.svg")}
                             onClick={() => setCollapsed(!collapsed())}></div>
                    </div>
                </header>
            </>
        );
    },
);
